import {
    useAppInsightsContext,
    useTrackEvent,
} from '@microsoft/applicationinsights-react-js';
import {
    Anchor,
    Body1,
    Flex,
    Heading1,
    Stack,
    TextInput,
    useNavBar,
} from '@phx/design-system';
import { useForm, useHotkeys } from '@phx/design-system/hooks';
import {
    type Geolocation,
    LocationPicker,
    useGeolocation,
    useLocationService,
} from '@phx/location-utils';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { z } from 'zod';

import { sendVerificationCodeApi } from '../../api/send-verification-code.api';
import { verifyAccountApi } from '../../api/verify-account.api';
import { PageLayout } from '../../components';
import {
    ErrorMap,
    type ErrorMessageTypes,
} from '../../components/alerts/errors/error-map';
import { SubmitButton } from '../../components/form-fields/SubmitButton';
import { telemetryInstance } from '../../instrumentation';
import type { ChallengeQuestionsFormModel } from '../../models/form.models';
import { routes } from '../../routes';
import type { VerifyAccountPageState } from '../verify-account/VerifyAccountPage';

const accessToken = import.meta.env.VITE_MAPBOX_ACCESS_TOKEN;

export type SignupChallengeQuestionsPageState = {
    phoneNumber: string;
    password: string;
    callBackUrl: string;
    userId?: string;
    email: string;
    firstName: string;
    lastName: string;
    dateOfBirth: string;
};

const SignupChallengeQuestions = () => {
    const { t } = useTranslation();
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const location = useLocation();
    const state = location?.state as SignupChallengeQuestionsPageState;
    const appInsights = useAppInsightsContext();
    const { challengeQuestionsEnabled } = useFlags();
    const { setBackOverrideState } = useNavBar();

    const trackForSubmit = useTrackEvent(
        appInsights,
        'CHALLENGEQUESTIONS_CONTINUE_CLICK',
        {}
    );
    const trackForSkipSubmit = useTrackEvent(
        appInsights,
        'CHALLENGEQUESTIONS_SKIP_CLICK',
        {}
    );
    const trackForSignin = useTrackEvent(
        appInsights,
        'CHALLENGEQUESTIONS_SIGNIN_CLICK',
        {}
    );

    const form = useForm<ChallengeQuestionsFormModel>({
        schema: z.object({
            address: z.string().optional(),
            memberId: z.string({
                message: t('formErrors.formErrorMessage.invalidMemberId'),
            }),
        }),
    });

    const [loading, setLoading] = useState<boolean>(false);
    const [errorMessageType, setErrorMessageType] = useState<
        ErrorMessageTypes | undefined
    >(undefined);

    const ErrorMessage = errorMessageType ? ErrorMap[errorMessageType] : null;

    const formAddress = form.values.address;
    const formMemberId = form.values.memberId;

    // Read parameters passed in redirect
    const userId = searchParams.get('user') ?? undefined;

    const onNavigateToSignIn = () => {
        trackForSignin({ message: 'Sign-in link is clicked' });
        navigate(
            { pathname: routes.signIn, search: window.location.search },
            { state: { backToSignUp: true } }
        );
    };

    const onNavigateBack = useCallback(() => {
        navigate(-1);
    }, []);

    useEffect(() => {
        setBackOverrideState({
            overrideFn: onNavigateBack,
        });

        return () => setBackOverrideState({ overrideFn: null });
    }, [onNavigateBack]);

    const skipFormSubmission = async () => {
        if (loading) {
            return;
        }

        setLoading(true);
        trackForSkipSubmit({ message: 'Challenge questions skipped' });

        const {
            firstName,
            lastName,
            dateOfBirth,
            phoneNumber,
            email,
            password,
            callBackUrl,
            userId,
        } = state;

        const { success: sendOtpSuccess, error: sendOtpError } =
            await sendVerificationCodeApi(phoneNumber);

        if (sendOtpSuccess) {
            trackForSkipSubmit({ message: 'OTP sent successfully' });
            const verifyPageState: VerifyAccountPageState = {
                firstName,
                lastName,
                dateOfBirth,
                phoneNumber,
                email,
                password,
                callBackUrl,
                userId,
                isSignupFlow: true,
            };
            navigate(routes.verify, {
                state: verifyPageState,
            });
            return;
        }

        trackForSkipSubmit({ message: 'Failed to send OTP' });
        setLoading(false);
        setErrorMessageType(sendOtpError);
        telemetryInstance.logError({
            source: location.pathname,
            exception: new Error(sendOtpError),
        });
    };

    const onFormSubmit = async (formData: ChallengeQuestionsFormModel) => {
        if (loading) {
            return;
        }

        setLoading(true);
        trackForSubmit({ message: 'Registration form is submitted' });

        const { address, memberId } = formData;
        const {
            firstName,
            lastName,
            dateOfBirth,
            phoneNumber,
            email,
            password,
            callBackUrl,
            userId,
        } = state;

        const {
            success: verifyAccountSuccess,
            userId: userIdFromVerifyResponse,
            error: verifyAccountError,
        } = await verifyAccountApi({
            firstName,
            lastName,
            email,
            dateOfBirth,
            phoneNumber,
            masterId: userId,
            memberId,
            address,
        });

        if (verifyAccountSuccess) {
            const { success: sendOtpSuccess, error: sendOtpError } =
                await sendVerificationCodeApi(phoneNumber);

            if (sendOtpSuccess) {
                trackForSubmit({ message: 'OTP sent successfully' });
                const verifyPageState: VerifyAccountPageState = {
                    firstName,
                    lastName,
                    dateOfBirth,
                    phoneNumber,
                    email,
                    password,
                    callBackUrl,
                    userId: userId ?? userIdFromVerifyResponse,
                    isSignupFlow: true,
                    address: address ?? null,
                };

                navigate(routes.verify, {
                    state: verifyPageState,
                });
                return;
            }

            trackForSubmit({ message: 'Failed to send OTP' });
            setLoading(false);
            setErrorMessageType(sendOtpError);
            telemetryInstance.logError({
                source: location.pathname,
                exception: new Error(sendOtpError),
            });

            return;
        }

        trackForSubmit({ message: 'Failed to verify account' });
        setLoading(false);
        setErrorMessageType(verifyAccountError);
        telemetryInstance.logError({
            source: location.pathname,
            exception: new Error(verifyAccountError),
        });

        if (verifyAccountError === '409_MultiplePatientsFoundForDemographic') {
            navigate(routes.signUpError);
        }
    };

    useHotkeys([['Enter', () => form.onSubmit(onFormSubmit)]]);

    const trackMemberId = useTrackEvent(
        appInsights,
        'SIGNUPCHALLENGEQUESTIONS_MEMBERID_CLICK',
        {}
    );
    const trackMailingAddress = useTrackEvent(
        appInsights,
        'SIGNUPCHALLENGEQUESTIONS_MAILINGADDRESS_CLICK',
        null
    );

    const { geolocation, setGeolocation } = useGeolocation();
    const locationService = useLocationService(accessToken, telemetryInstance);

    const handleSetGeolocation = useCallback(
        (location?: Geolocation) => {
            if (location?.address) {
                setGeolocation(location);
                form.setFieldValue('address', {
                    line1: location.address.line1,
                    line2: location.address.line2,
                    city: location.address.city,
                    state: location.address.state,
                    country: location.address.country,
                    postalCode: location.address.zip,
                });
            }
        },
        [setGeolocation]
    );

    useEffect(() => {
        if (!challengeQuestionsEnabled) {
            navigate(routes.signUp, { state: { backToSignIn: false } });
        }
    }, [challengeQuestionsEnabled, navigate]);

    const memberIdProps = form.getInputProps('memberId');
    memberIdProps.onFocus = () => {
        trackMemberId({ message: 'Member ID field is clicked' });
        form.getInputProps('memberId').onFocus();
    };

    if (!challengeQuestionsEnabled) {
        return null;
    }

    return (
        <PageLayout
            loadingOverlay={loading}
            header={
                !loading
                    ? errorMessageType &&
                      ErrorMessage && (
                          <ErrorMessage onLinkClick={onNavigateToSignIn} />
                      )
                    : null
            }
            heading={
                <Stack gap="sm">
                    <Heading1>
                        {t('signUpChallengeQuestionsPage.planMember')}
                    </Heading1>
                    <Body1>
                        {t('signUpChallengeQuestionsPage.provideCredentials')}
                    </Body1>
                </Stack>
            }
            footer={
                <Stack gap="sm">
                    <SubmitButton
                        form="challenge-questions-form"
                        disabled={!formAddress && !formMemberId}
                    >
                        {t('common.continue')}
                    </SubmitButton>
                    <Flex justify="center" gap="xxxs">
                        <Body1>
                            {t('signUpChallengeQuestionsPage.notPlanMember')}
                        </Body1>
                        <Anchor
                            onClick={skipFormSubmission}
                            data-testid="skip-challenge-questions-link"
                        >
                            {t('signUpChallengeQuestionsPage.skip')}
                        </Anchor>
                    </Flex>
                </Stack>
            }
        >
            <form
                id="challenge-questions-form"
                onSubmit={form.onSubmit(onFormSubmit)}
            >
                <Stack gap="md" pt="md">
                    {userId ? (
                        <TextInput
                            key={form.key('memberId')}
                            name="memberId"
                            label={t('challengeQuestionsForm.memberId')}
                            placeholder={t(
                                'challengeQuestionsForm.enterMemberId'
                            )}
                            required
                            {...memberIdProps}
                        />
                    ) : null}
                    <LocationPicker
                        geolocation={geolocation}
                        onFocus={() => trackMailingAddress(null)}
                        onLocationChange={handleSetGeolocation}
                        placeholder={t('challengeQuestionsForm.mailingAddress')}
                        locationService={locationService}
                    />
                </Stack>
            </form>
        </PageLayout>
    );
};

export default SignupChallengeQuestions;
